import React from "react"
import { Link } from "gatsby"
import slugify from "slugify"
import "./blog-sidebar.scss"

const listStyling = {
  padding: "5px 20px",
  borderRadius: "5px",

  fontSize: "15px",
  // backgroundColor: "#08244c",
  fontWeight: "100",
  display: "block",
  // border: "1px solid #ccc",
  transition: ".3s",
  color: "#08244c",
}

const BlogSidebar = ({ posts, cat }) => {
  const articles = posts
  const categories = cat

  return (
    <div className="blog-sidebar pl-5 pt-5">
      <div className="article-topics pb-5">
        <h2
          className="h4 font-weight-bold"
          style={{ padding: "0 0 20px 30px" }}
        >
          Topics
        </h2>
        <div className="position-relative">
          <ul
            className="list-unstyled mb-0"
            style={{
              backgroundColor: "#d1d6df",
              padding: "20px 10px",
              zIndex: "1",
            }}
          >
            {categories.map((c, cIdx) => (
              <li key={cIdx} className="">
                <Link
                  to={`/category/${slugify(c.title.toLowerCase())}/`}
                  style={listStyling}
                >
                  {c.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BlogSidebar
