import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import placeholder from "../images/place-holder.webp"
import stick from "../images/stick.webp"
import BlogSidebar from "../components/blogSidebar/sidebar"
import ArticleListItem from "../components/articleList/articleListItem"
import { getSrc } from "gatsby-plugin-image"
import { StaticImage }  from "gatsby-plugin-image"

const Category = ({ data, pageContext }) => {
  const title = pageContext.title

  // for sidebar
  const categories = data.allSanityCategory.edges.map((t) => t.node)
  const recentMemos = data.sideArticles.edges.map((t) => t.node)

  return (
    <Layout>
      <SEO title={title} />
      <div className="blog-page">
        <div className="blog-page-heading">
          <div className="container">
            <span className="h6 text-white font-weight-bold text-uppercase">
              Topic
            </span>
            <h1 className="h2 text-uppercase">{title}</h1>
            <StaticImage src="../images/stick.webp" className="stick" alt="stick" loading="eager"/>
          </div>
        </div>
        <div className="py-110">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-9 mb-5 mb-md-4">
                {data.allSanityPost.edges.map((blog) => (
                  <ArticleListItem
                    idx={blog}
                    link={blog.node.slug.current}
                    banner={
                      blog?.node?.mainImage !== null
                        ? getSrc(blog?.node?.mainImage?.asset?.gatsbyImageData)
                        : placeholder
                    }
                    categories={
                      blog.node.categories.length ? blog.node.categories : null
                    }
                    title={blog.node.title}
                    excerpt={
                      blog.node.excerpt
                        ? blog.node.excerpt.length > 70
                          ? `${blog.node.excerpt.substring(0, 70)}...`
                          : blog.node.excerpt
                        : blog.node.videoUrl
                        ? blog.node.videoUrl
                        : null
                    }
                    authorImage={
                      getSrc(blog?.node?.authors?.image?.asset?.gatsbyImageData)
                        ? getSrc(
                            blog?.node?.authors?.image?.asset?.gatsbyImageData
                          )
                        : null
                    }
                    author={blog.node.authors.name}
                    publishedAt={blog.node.publishedAt}
                  />
                ))}
              </div>
              <div className="col-md-3 mb-4">
                <BlogSidebar posts={recentMemos} cat={categories} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Category

export const blogQuery = graphql`
  query CategoryTemplateQuery($title: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: $title } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          excerpt
          slug {
            current
          }
          categories {
            title
          }
          authors {
            name
            image {
              asset {
                gatsbyImageData(width: 40, height: 40)
              }
            }
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
    sideArticles: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featured
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          videoUrl
          categories {
            id
            title
          }
          authors {
            name
            image {
              asset {
                gatsbyImageData(width: 32, height: 32)
              }
            }
          }
        }
      }
    }
  }
`
